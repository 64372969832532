import routes from 'next-routes';
import { Link, Router } from './i18n';
import { pages } from '../constants/routes';

const appRoutes = routes({
  Link,
  Router,
});

// Adding pages with parametrized Urls (and UK prefix)
Object.values(pages).forEach((page) => {
  if (page.paramUrl) {
    appRoutes.add(`${page.page}-param`, `${page.paramUrl}`, page.page);
  }
});

export default appRoutes;
