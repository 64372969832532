export const pages = {
  ROUTE_ROOT: {
    page: 'index',
    url: '',
  },
  ROUTE_DASHBOARD: {
    page: 'dashboard',
    url: '/dashboard',
  },
  ROUTE_LEGAL: {
    page: 'legal',
    url: '/legal',
    paramUrl: '/legal/:tabId',
  },
};

export const routes = {
  ROUTE_ROOT: pages.ROUTE_ROOT.url,
};

export const REDIRECT_ROUTES = {
  '/support/docs': '/legal',
  '/regulatory': '/legal/regulatory',
  '/support': 'https://support.plume.com/hc/en-us',
  '/uk/support-uk': 'https://support.plume.com/hc/en-gb',
  '/partnersupport': 'https://plumepartners.zendesk.com',
  '/pricing': '/store',
  '/store/order/shipping': '/store/checkout',
  '/store/order/pay': '/store/checkout',
  '/store/order/review': '/store/checkout',
  '/store/bundles/1': '/configurator',
  '/store/bundles/1/pods': '/configurator',
  '/store/bundles/3': '/configurator',
  '/store/bundles/3/pods': '/configurator',
  '/store/bundles/4': '/configurator',
  '/store/bundles/4/pods': '/configurator',
  'sunrise.plume.com/setup': 'https://support.plume.com/hc/de/articles/360035427354',
  'sunrise.plume.com/': 'https://www.plume.com/ch/sunrise/store',
  '/homepass/techmeme': '/homepass/store?utm_source=techmeme',
  '/homepass/hacked': '/homepass/store?utm_source=hacked',
  '/homepass/scifigen': '/homepass/store?utm_source=scifigen',
  '/homepass/techrevolution': '/homepass/store?utm_source=techrevolution',
  '/homepass/pessimists': '/homepass/store?utm_source=pessimists',
  '/homepass/support/docs': '/legal',
  '/homepass/regulatory': '/legal/regulatory',
  '/homepass/support': 'https://support.plume.com/hc/en-us',
  '/homepass/uk/support-uk': 'https://support.plume.com/hc/en-gb',
  '/homepass/partnersupport': 'https://plumepartners.zendesk.com',
  '/homepass/pricing': '/store',
  '/homepass/store/order/shipping': '/store/checkout',
  '/homepass/store/order/pay': '/store/checkout',
  '/homepass/store/order/review': '/store/checkout',
  '/homepass/store/bundles/1': '/configurator',
  '/homepass/store/bundles/1/pods': '/configurator',
  '/homepass/store/bundles/3': '/configurator',
  '/homepass/store/bundles/3/pods': '/configurator',
  '/homepass/store/bundles/4': '/configurator',
  '/homepass/store/bundles/4/pods': '/configurator',
};
