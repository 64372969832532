// import routes from 'next-routes';
// import { Link as i18Link, Router as i18Router } from './i18n';

// export const Link = routes({ Link: i18Link });
// export const Router = routes({ Router: i18Router });
import routes from './routes';

export const {
  Link, Router,
} = routes;
